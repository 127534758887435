import axios from 'axios';
import global from '@/components/Global.vue';

let base = global.esaioServerBase;

export const createEsaioStationInfo = params => {

    let formData = new FormData();
    if (params.fileList && params.fileList.length > 0) {

        formData.append('imageFile', params.fileList[0].raw);
    }
    formData.append("orgId", params.dto.orgId);
    formData.append("areaId", params.dto.areaId);
    formData.append("name", params.dto.name);
    formData.append("address", params.dto.address);
    formData.append("baiduLng", params.dto.baiduLng);
    formData.append("baiduLat", params.dto.baiduLat);
    formData.append("type", params.dto.type);
    formData.append("capacity", params.dto.capacity);
    formData.append("imageUrl", params.dto.imageUrl);
    return axios({

        url: base + '/esaioStationInfo/createEsaioStationInfo',
        method: 'post',
        data: formData
    });
}

export const removeEsaioStationInfo = params => {

    return axios.post(base + '/esaioStationInfo/removeEsaioStationInfo', params);
}

export const modifyEsaioStationInfo = params => {

    let formData = new FormData();
    if (params.fileList && params.fileList.length > 0) {

        formData.append('imageFile', params.fileList[0].raw);
    }
    formData.append("id", params.dto.id);
    formData.append("orgId", params.dto.orgId);
    formData.append("areaId", params.dto.areaId);
    formData.append("name", params.dto.name);
    formData.append("address", params.dto.address);
    formData.append("baiduLng", params.dto.baiduLng);
    formData.append("baiduLat", params.dto.baiduLat);
    formData.append("type", params.dto.type);
    formData.append("capacity", params.dto.capacity);
    formData.append("imageUrl", params.dto.imageUrl);
    return axios({

        url: base + '/esaioStationInfo/modifyEsaioStationInfo',
        method: 'post',
        data: formData
    });
}

export const queryEsaioStationInfoList = params => {

    return axios.post(base + '/esaioStationInfo/queryEsaioStationInfoList', params);
}

export const queryEsaioStationInfoById = params => {

    return axios.post(base + '/esaioStationInfo/queryEsaioStationInfoById', params);
}

export const queryStationAssignUserList = params => {

    return axios.post(base + '/esaioStationInfo/queryStationAssignUserList', params);
}

export const assignUsersToStations = params => {

    return axios.post(base + '/esaioStationInfo/assignUsersToStations', params);
}

export const queryEsaioStationArea = () => {

    return axios.post(base + '/esaioStationInfo/queryEsaioStationArea');
};

export const queryCurUserEsaioStationOrgTree = () => {

    return axios.post(base + '/esaioStationInfo/queryCurUserEsaioStationOrgTree');
}